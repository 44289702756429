import { useEffect } from "react";

import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";

import styles from "./RegistrationResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";
const RegistrationResult = () => {

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_access_plan_appeared');
    }, [])

    const downloadApp = () => {
        amplitude.getInstance().logEvent('button_onboarding__access_plan_download_tapped');
        window.location.replace('https://m.everdance.app/link/is8z')
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>{strings.registration.created}</h1>
            <p className={styles.subtitle}>{strings.registration.nextSteps}</p>
            <div className={styles.stepsContainer}>
                <div className={`${styles.step} flex-column`}>
                    <div className={`${styles.stepPoint} flex-row`}>
                        <span className={`${styles.stepNumber} ${styles.stepOne}`}></span>
                        <div className={`${styles.stepTitle}`}>
                            <span onClick={downloadApp}>{strings.registration.download}</span>
                            {strings.registration.toPhone}
                        </div>
                    </div>
                </div>
                <div className={`${styles.step} flex-column`}>
                    <div className={`${styles.stepPoint} flex-row`}>
                        <span className={`${styles.stepNumber} ${styles.stepTwo}`}></span>
                        <div className={`${styles.stepTitle}`}>
                            {strings.registration.use} <b>{strings.registration.login}</b>{strings.registration.toLogin}
                            <p className={`${styles.stepSubtitle}`}>
                                {strings.registration.youShould}
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.stepContent} flex-row`}>
                        <img className={styles.image} src="/images/result/registration-step2.png" alt="" />
                    </div>
                </div>
                <div className={`${styles.step} flex-column`}>
                    <div className={`${styles.stepPoint} flex-row`}>
                        <span className={`${styles.stepNumber} ${styles.stepThree}`}></span>
                        <div className={`${styles.stepTitle}`}>
                           {strings.registration.enter}
                        </div>
                    </div>
                    <div className={`${styles.stepContent} flex-row`}>
                        <img className={styles.image} src="/images/result/registration-step3.png" alt="" />
                    </div>
                </div>
            </div>
            <button className={styles.buttonContainer} onClick={downloadApp} >
               {strings.registration.download}
            </button>
        </div>
    )
}   

export default RegistrationResult;