import styles from "./ImageSaleAd.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const ImageSaleAd = ({ campaign, pageInfo, style }) => {
    const containerStyle = style === 'bf'
                           ? styles.bFContainer
                           : style === 'ny'
                             ? styles.nYContainer
                             : ""
//     (campaign === 'bf' || campaign === 'sh' || campaign === 'shret022470' || campaign === 'sh032450' || campaign === 'sh032470') :
//                            campaign === 'ny' ? styles.nYContainer : ""
    let saleImgPath = campaign === "chl" || campaign === "chl_ret70"
                      ? "/images/paywall/a/paywall-a_chl.png"
                      : "/images/paywall/a/paywall-a.gif"
    return (
        <div className={`${styles.galleryImageSale} ${containerStyle}`}>
            <div className={`${styles.imageSaleAd} ${styles.imageSaleAdTop} flex-row`}>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                    <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                    <img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />

                    { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>) }
                    { pageInfo.saleID !== 'trial' && (<img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />)}

                    <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                    <img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />

                   { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>)}
                   { pageInfo.saleID !== 'trial' && (<img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />)}

                    <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                </div>
{/*                 <div className={`${styles.imageSaleAdText} flex-row`}> */}
{/*                     <span>{eventInfo.saleAdName}</span> */}
{/*                     <img className={styles.imageSaleStar} src={eventInfo.saleAdImage} alt="" /> */}
{/*                     <span>SALE</span> */}
{/*                 </div> */}
            </div>
            <img className={styles.galleryImage} src={saleImgPath} alt="" />
            <div className={`${styles.imageSaleAd} ${styles.imageSaleAdBottom} flex-row`}>
                <div className={`${styles.imageSaleAdText} flex-row`}>
                  <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                  <img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />

                 { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>)}
                 { pageInfo.saleID !== 'trial' && (<img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />)}

                  <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                  <img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />

                 { pageInfo.saleID !== 'trial' && (<span style={{"fontSize": strings.paywall.cardHeader.sale.fontSize}}>{strings.paywall.cardHeader.sale.text}</span>)}
                 { pageInfo.saleID !== 'trial' && (<img className={styles.imageSaleStar} src={pageInfo.saleAdImage} alt="" />)}

                  <span style={{"fontSize": strings.paywall.cardHeader.imageTitle[pageInfo.saleID].fontSize}}>{strings.paywall.cardHeader.imageTitle[pageInfo.saleID].text}</span>
                </div>
{/*                 <div className={`${styles.imageSaleAdText} flex-row`}> */}
{/*                     <span>{eventInfo.saleAdName}</span> */}
{/*                     <img className={styles.imageSaleStar} src={eventInfo.saleAdImage} alt="" /> */}
{/*                     <span>SALE</span> */}
{/*                 </div> */}
            </div>
        </div>
    )
}

export default ImageSaleAd;