import React from 'react';
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardElement, CardNumberElement, CardMonthElement, CardYearElement, CardCvvElement, useRecurly } from '@recurly/react-recurly';
import styles from "./CheckoutForm.module.scss";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./RecurlyPaymentForm.scss";
import amplitude from 'amplitude-js';
import sha256 from 'crypto-js/sha256';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import moment from "moment/moment";

export function RecurlyPaymentForm (props) {
  const { period } = props;
  const formRef = React.useRef();
  const recurly = useRecurly();
  const [isProcessing, setIsProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [succeeded, setSucceeded] = useState(null);
  const [cardHolderFirstName, setCardHolderFirstName] = useState(null);
  const [cardHolderLastName, setCardHolderLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [applePayReady, setApplePayReady] = useState(false);
  const [appleSupport, setAppleSupport] = useState(false);
  const [googleSupport, setGoogleSupport] = useState(false);
  const [paypalClientId, setPaypalClientId] = useState('Af7bctxkCUaREhFJ0BemXCIe_wjt63BQZ6lAPH58TSx-ew4SMvdCAXT5vKgf0iiAPGmGGo9TGSnsRddE');

  const navigate = useNavigate();

  let subscriptionResult;

  const capiToken = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';

  const paypal = recurly.PayPal({
    display: { displayName: ' Everdance subscription' }
  });

  const ref = useRef(null);

  // Handle when the token has been generated to keep the payment sheet in the "Processing"
  // state until the onPaymentAuthorized callback has completed
  async function onPaymentAuthorized({ payment }) {
    console.log(payment);
    const { recurlyToken: token, billingContact, shippingContact } = payment;
    // Submit the token to your server
    console.log('Apple Pay succeeded.',
                'Token:', token.id,
                'billingContact:', billingContact,
                'shippingContact', shippingContact);
    console.log(billingContact);
     let invoiceCollection = await createSubscription(token.id, billingContact.email, billingContact.givenName, billingContact.familyName, period?.recurly_card_id,);

                        console.log(invoiceCollection);
                        if (subscriptionResult.subscription !== null && subscriptionResult.status !== null && (subscriptionResult.status === "active")) {
                                                        facebookCapi('Subscribe', `subscribe.${getCookie('id')}`, billingContact.email, period?.recurly_card_id, period?.total);
                                                        facebookCapi('Purchase', `subscribe.${getCookie('id')}`, billingContact.email, period?.recurly_card_id, period?.total);
                                                        const eventId = `subscribe.${getCookie('id')}`;
                                                        ReactPixel.track('Subscribe',  {subscription_id: subscriptionResult.subscription,
                                                                                       value: period?.total,
                                                                                       currency: 'USD'},
                                                                                       {eventID: eventId}
                                                                                       );
                                                        ReactPixel.track('Purchase', {subscription_id: subscriptionResult.subscription,
                                                                                     value: period?.total,
                                                                                     currency: 'USD'},
                                                                                     {eventID: eventId}
                                                                                     );
                                                        setSucceeded(true);
                                                    } else {
                                                        setSucceeded(false);
                                                    }

  }

  async function onGooglePaymentAuthorized(paymentData) {
    console.log(paymentData);
    const { recurlyToken: token } = paymentData;
    // Submit the token to your server
    console.log('Google Pay succeeded.',
                'Token:', token.id,
                'PaymentData:', paymentData);
    let invoiceCollection = await createSubscription(token.id, billingContact.email, billingContact.givenName, billingContact.familyName, period?.recurly_card_id,);

                            console.log(invoiceCollection);
                            if (subscriptionResult.subscription !== null && subscriptionResult.status !== null && (subscriptionResult.status === "active")) {
                                                            facebookCapi('Subscribe', `subscribe.${getCookie('id')}`, billingContact.email, period?.recurly_card_id, period?.total);
                                                            facebookCapi('Purchase', `subscribe.${getCookie('id')}`, billingContact.email, period?.recurly_card_id, period?.total);
                                                            const eventId = `subscribe.${getCookie('id')}`;
                                                            ReactPixel.track('Subscribe',  {subscription_id: subscriptionResult.subscription,
                                                                                           value: period?.total,
                                                                                           currency: 'USD'},
                                                                                           {eventID: eventId}
                                                                                           );
                                                            ReactPixel.track('Purchase', {subscription_id: subscriptionResult.subscription,
                                                                                         value: period?.total,
                                                                                         currency: 'USD'},
                                                                                         {eventID: eventId}
                                                                                         );
                                                            setSucceeded(true);
                                                        } else {
                                                            setSucceeded(false);
                                                        }
  }

  const applePay = recurly.ApplePay({
    country: 'US',
    currency: 'USD',
    label: 'Everdance Subscription', // This text will be displayed on the Apple Pay payment sheet as "My Subscription"
    total: '0.01',
    callbacks: {
      onPaymentAuthorized,
    }
  });

  const googlePay = recurly.GooglePay({
    currency: 'USD',
    country: 'US',
    total: '0.01',
    googleMerchantId: 'BCR2DN4TZ2FJX2KN',
    billingAddressRequired: false,
    buttonOptions: {
        buttonSizeMode: "fill",
    },
    callbacks: {
      onGooglePaymentAuthorized,
    },
  });

  const appleButtonStyle = {
      paymentRequestButton: {
        height: '48px',
      }
  }

  useEffect(() => {

      // Handle errors. These may occur at any point in the Apple Pay flow
      applePay.on('error', function (err) {
        setAppleSupport(false);
        console.log('Apple Pay error', err);
      });

      applePay.on('token', function (token) {
          console.log('Apple Pay token', token);

      });

      googlePay.on('token', async function (token, payment_detail) {
        console.log('Google Pay token', token);
        console.log(payment_detail);
        let invoiceCollection = await createSubscription(token.id, "test@mail.org", "First name", "Last name", period?.recurly_card_id,);
        console.log(invoiceCollection);
        if (subscriptionResult.subscription !== null && subscriptionResult.status !== null && (subscriptionResult.status === "active")) {
          facebookCapi('Subscribe', `subscribe.${getCookie('id')}`, "jhjhj", period?.recurly_card_id, period?.total);
          facebookCapi('Purchase', `subscribe.${getCookie('id')}`, "khjhjh", period?.recurly_card_id, period?.total);
          const eventId = `subscribe.${getCookie('id')}`;
          ReactPixel.track('Subscribe',  {subscription_id: subscriptionResult.subscription,
                                          value: period?.total,
                                          currency: 'USD'},
                                          {eventID: eventId}
                                          );
          ReactPixel.track('Purchase', {subscription_id: subscriptionResult.subscription,
                                        value: period?.total,
                                        currency: 'USD'},
                                        {eventID: eventId}
                                        );
          setSucceeded(true);
        } else {
          setSucceeded(false);
        }
      });

      // When the ApplePay instance is ready, bind the Apple Pay button click
      // to applePay.begin
      applePay.ready(function () {
        setApplePayReady(true);
        setAppleSupport(true);
      });

      // When the ApplePay instance is ready, bind the Apple Pay button click
           // to applePay.begin
      googlePay.on('ready', function (googlePayButton) {
           setGoogleSupport(true);
           const element = ref.current;
           element.appendChild(googlePayButton);
      });

      // Handle errors. These may occur at any point in the Google Pay flow
      googlePay.on('error', function (err) {
        setGoogleSupport(false);
        console.log('Google Pay error', err);
        console.log(err.statusMessage);
      });


          if(succeeded !== null && succeeded) {
              navigate('/registration');
          } else if(succeeded !== null && !succeeded) {
              navigate('/failure');
          }
      }, [succeeded])

      async function onPayPalToken(token){
      console.log("paypal token");
      console.log(token);
        let invoiceCollection = await createSubscription(token.id, token.email, "cardHolderFirstName", "cardHolderLastName", period?.recurly_card_id,);

                  console.log(invoiceCollection);
                  if (subscriptionResult.subscription !== null && subscriptionResult.status !== null && (subscriptionResult.status === "active")) {
                                                  facebookCapi('Subscribe', `subscribe.${getCookie('id')}`, token.email, period?.recurly_card_id, period?.total);
                                                  facebookCapi('Purchase', `subscribe.${getCookie('id')}`, token.email, period?.recurly_card_id, period?.total);
                                                  const eventId = `subscribe.${getCookie('id')}`;
                                                  ReactPixel.track('Subscribe',  {subscription_id: subscriptionResult.subscription,
                                                                                 value: period?.total,
                                                                                 currency: 'USD'},
                                                                                 {eventID: eventId}
                                                                                 );
                                                  ReactPixel.track('Purchase', {subscription_id: subscriptionResult.subscription,
                                                                               value: period?.total,
                                                                               currency: 'USD'},
                                                                               {eventID: eventId}
                                                                               );
                                                  TiktokPixel.track('CompletePayment', {event_id: eventId, value: period?.total, currency: 'USD', quantity: 1});
                                                  setSucceeded(true);
                                              } else {
                                                  setSucceeded(false);
                                              }
        }


  async function handleSubmit (event) {
    console.log(period);
    facebookEvent();
    event.preventDefault();
    setIsProcessing(true);

    facebookCapi('AddPaymentInfo', `paymentinfo.${getCookie('id')}`, email, period?.recurly_card_id);
    amplitude.getInstance().logEvent('cookies', {cookies: document.cookie});

    recurly.token(formRef.current, async (err, token) => {
      if (err) {
      console.log(err);
        // handle error
       setError(`Payment failed ${err.message}`);
       setIsProcessing(false);
      } else {
      console.log(token);
        // save the token.id, and submit it to the Recurly API from your server


          let invoiceCollection = await createSubscription(token.id, email, cardHolderFirstName, cardHolderLastName, period?.recurly_card_id,);

          console.log(invoiceCollection);
          if (subscriptionResult.subscription !== null && subscriptionResult.status !== null && (subscriptionResult.status === "active")) {
                                          facebookCapi('Subscribe', `subscribe.${getCookie('id')}`, email, period?.recurly_card_id, period?.total);
                                          facebookCapi('Purchase', `subscribe.${getCookie('id')}`, email, period?.recurly_card_id, period?.total);
                                          const eventId = `subscribe.${getCookie('id')}`;
                                          ReactPixel.track('Subscribe',  {subscription_id: subscriptionResult.subscription,
                                                                         value: period?.total,
                                                                         currency: 'USD'},
                                                                         {eventID: eventId}
                                                                         );
                                          ReactPixel.track('Purchase', {subscription_id: subscriptionResult.subscription,
                                                                       value: period?.total,
                                                                       currency: 'USD'},
                                                                       {eventID: eventId}
                                                                       );
                                          setSucceeded(true);
                                      } else {
                                          setSucceeded(false);
                                      }
      }
    });
  }

   const facebookEvent = () => {
        const eventId = `paymentinfo.${getCookie('id')}`
        ReactPixel.track('AddPaymentInfo', {eventID: eventId});
   }

   function getCookie(name) {
          const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          return matches ? decodeURIComponent(matches[1]) : undefined;
      }

      function facebookCapi(name, eventId, email, productId, total) {
          const payload = {
              data: [
                  {
                      event_name: name,
                      event_time: moment().unix(),
                      action_source: "website",
                      event_id: eventId,
                      user_data: {
                              subscription_id: productId,
                              client_user_agent: navigator.userAgent,
                              external_id: sha256(getCookie('id')).toString()
                      }
                  }
              ]
          }

          if(email) {
              payload.data[0].user_data.em = [ sha256(email).toString()]
          }

          if(total) {
              payload.data[0].custom_data = {
                  "currency": "USD",
                  "value": total
              }
          }

          const fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]).join();
          const fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]).join();

          if(fbc) {
              payload.data[0].user_data.fbc = fbc;
          }

          if(fbp) {
              payload.data[0].user_data.fbp = fbp;
          }

          fetch(`https://graph.facebook.com/v10.0/${(domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${capiToken}`, {
              method: "POST",
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(payload)
          })
      }

  const paypalOnClick = (event) => {
    event.preventDefault();
    console.log("PayPalOnClick")
    amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped');
    paypal.start();
    paypal.on('token', onPayPalToken);
    paypal.on('error', function (err) {
      // err.code
      // err.message
      console.log('pay pal error', err);
      // [err.cause] if there is an embedded error
    });
  };

  const applePayOnClick = () => {
    console.log("applePayOnClick");
    amplitude.getInstance().logEvent('button_onboarding_payment_apple_pay_tapped');
      // When the ApplePay instance is ready, bind the Apple Pay button click
          // to applePay.begin
    if(applePayReady){
      console.log("applePay begin");
      applePay.begin();
    }
  };

  const googlePayOnClick = () => {
    console.log("googlePayOnClick");
    amplitude.getInstance().logEvent('button_onboarding_payment_google_pay_tapped');
  };

  const createSubscription = async (token, email, firstName, lastName, plan) => {
    await fetch("https://1fqev002he.execute-api.us-east-1.amazonaws.com/dev/recurly_subscription", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: token,
//      paymentMethodId: paymentMethodId,
        email: email,
        first_name: firstName,
        last_name: lastName,
        plan: plan
      }),
    }).then(async (response) => {
      console.log("createSubscription result");
      let resultBody = response.json();
      await resultBody.then( async (json) => {
        console.log(json);
        console.log({id: json.subscription, status: json.status});
        subscriptionResult = {id: json.subscription, status: json.status};
        return json;
      });
    });
  }

  const handleChange = async (event) => {
    setDisabled(false);
    setError(event.error ? event.error.message : "");
  };

  return (
  <>
    <div className={`${styles.buttonsPaymentContainer}`} onClick={facebookEvent}>
      <div className={styles.paypal} onClick={() => paypalOnClick() }>
        <span className={styles.logo}>
          <i className={styles.first}>Pay</i><i className={styles.last}>Pal</i>
        </span>
      </div>
      { appleSupport ? <div className={styles.applePay} onClick={() => applePayOnClick() }></div> : null }
      { googleSupport ? <div ref={ref} id="google-pay-button-container"> </div> : null }
    </div>
    <p className={styles.textOr}>or</p>
    <div className={`${styles.labelsContainer} flex-between`}>
      <div className={`${styles.imagesContainer} flex-row`}>
        <img src="/images/icons/payment.png" alt="" />
      </div>
    </div>
    <form onSubmit={handleSubmit} ref={formRef} className={styles.paymentContainer} id="payment-form">
      <div className={`${styles.firstLine} flex-row`}>
        <CardNumberElement
          onChange={handleChange}
          displayIcon
          style={{
            displayIcon: true,
            placeholder: {
              color: '#BBBBBB',
              fontWeight: 500,
              fontSize: '12px',
              content: 'Card number'
            },
          }}
        />
        <CardMonthElement
          onChange={handleChange}
          style={{
            placeholder: {
              color: '#BBBBBB',
              fontWeight: 500,
              fontSize: '12px',
              content: 'Month'
            }
          }}
        />
        <CardYearElement
          onChange={handleChange}
          style={{
            placeholder: {
              color: '#BBBBBB',
              fontWeight: 500,
              fontSize: '10px',
              content: 'Year'
            }
          }}
        />
        <CardCvvElement
          onChange={handleChange}
          style={{
            placeholder: {
              color: '#BBBBBB',
              fontWeight: 500,
              fontSize: '12px',
              content: 'CVC'
            }
          }}
        />
      </div>
      {error && (
        <div className={styles.errorContainer}>
            <p id="error_form_field" className={styles.errorFormField}>{error}</p>
        </div>
      )}
      <div className={styles.inputCardHolder}>
        <div className={`${styles.firstLine} flex-row`}>
          <input id="name"
                onChange={(e => setCardHolderFirstName(e.target.value))}
                data-recurly="first_name"
                name="name"
                className={`${styles.field} ${styles.fieldCardHolderFirstName} ${styles.marginFieldHolder}`}
                placeholder="First name" pattern="^[А-Яа-яЁёA-Za-z\s]+$"
          />
          <input id="last_name"
                data-recurly="last_name"
                name="last_name"
                onChange={(e => setCardHolderLastName(e.target.value))}
                className={`${styles.field} ${styles.fieldCardHolderLastName} ${styles.marginFieldHolder}`}
                placeholder="Last name" pattern="^[А-Яа-яЁёA-Za-z\s]+$"
          />
        </div>
      </div>
      <div>
        <input id="email" onChange={e => setEmail(e.target.value)} name="email"
            className={`${styles.field} ${styles.fieldCardHolderName} ${styles.marginEmail}`}
            pattern="[А-Яа-яЁёA-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="E-mail"
        />
      </div>
      <p className={styles.message}>After successful payment, please check your email.</p>
      <button className={`${styles.payButton} ${isProcessing || disabled ? styles.disableButton : ''}`} disabled={isProcessing || disabled} id="submit">
        <span className={styles.buttonText}>Continue</span>
        <span className="pulse-button__rings"></span>
        <span className="pulse-button__rings"></span>
        <span className="pulse-button__rings"></span>
      </button>
      <p className={styles.buttonMessage}>*You can cancel anytime. All prices are in USD.</p>
      <div className={`${styles.linksContainer} flex-between`}>
          <a href="https://everdance.app/policy" target="_blank">Privacy Policy</a>
          <a href="https://everdance.app/terms"  target="_blank">Terms&Service</a>
      </div>
    </form>
    </>
  );
}