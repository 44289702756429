
import styles from "./TextCard.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const TextCard = props => {
    const { card, cardHeight, selectCard, activeCard } = props;
    console.log(card)
    return (
        <>
            <div className={`${styles.cardContainer} ${activeCard?.id === card.id  ? styles.activeCard : ''} flex-row cardContainer`} style={{height: cardHeight ? `${cardHeight}px` : ''}} onClick={() => selectCard(card)}>
                <img className={styles.cardIcon} src={card.icon} alt="" />
                {
                    card.message ? (
                        <div className={styles.contentContainer}>
                            <p className={`${styles.cardTitle} cardTitle`} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{strings[card.section][card.page].labels[card.localID]["text"]}</p>
                           {card.subtext && (<span className={styles.cardSubtitle} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{strings[card.section][card.page].labels[card.localID]["subtext"]}</span>)}
                        </div>
                    ) : (
                        <p className={`${styles.cardTitle} cardTitle`} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{strings[card.section][card.page].labels[card.localID]}</p>
                    )
                }
            </div>
            {
                card.message && activeCard?.id === card.id ? (
                    <p className={styles.cardMessage}>{strings[card.section][card.page].labels[card.localID]["subtext"]}</p>
                ) : null
            }
        </>
    )
}   

export default TextCard;