import styles from "./ResultHeader.module.scss";

const ResultHeader = props => {
    const { url } = props;
    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <div className={styles.circleContainer}>
                <img src={url} alt="" />
            </div>
        </div>
    )
}   

export default ResultHeader;