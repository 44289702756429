import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./LifestyleResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const LifestyleResult = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.lifestyleResult

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_lifestyle_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_lifestyle_tapped');
        nextStep();
    }
    let resultImage = data.campaign === "chl" || data.campaign === "chl_ret70"
                      ? "/images/result/lifestyle-result-image_chl.png"
                      : "/images/result/lifestyle-result-image.png"
    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader url={'/images/result/lifestyle-result.jpg'}/>
            {
                pageInfo.asComment ? (
                    <>
                        <h1 className={styles.commentTitle}>{strings.lifestyle.result.title}</h1>
                        <div className={`${styles.comment} flex-row`}>
                            <div className={styles.commentImageBox}>
                                <img className={styles.commentImage}
                                src={resultImage} alt="" />
                            </div>
                            <div className={`${styles.commentArea} flex-column`}>
                                <img src="/images/stars.svg" alt="" />
                                <p className={styles.commentText}>
                                    {strings.lifestyle.result.comment}
                                </p>
                                <p className={styles.commentAuthor}>
                                    {strings.lifestyle.result.commentAuthor}
                                </p>
                            </div>
                        </div>
                        <span className={styles.commentHint}>
                            {strings.lifestyle.result.commentHint.start}
                             <span className={styles.hintBold}>{strings.lifestyle.result.commentHint.middle}</span>
                            {strings.lifestyle.result.commentHint.end}
                        </span>
                        <ContinueButton text={strings.continue} nextStep={clickContinue}/>
                    </>
                ) : (
                    <>
                        <h1 className={styles.title}>Everdance was made for people just like you!</h1>
                        <img className={styles.image} src="/images/result/lifestyle-image.jpg" alt="" />
                        <p className={`${styles.text} resultSubtitle`}><span>83%</span> of Everdance users</p>
                        <span className={`${styles.subtitle} componentSubtitle`}>claim that the dance plan we offer is easy to follow and makes it simple to stay on track.</span>
                        <ContinueButton text={'All right'} nextStep={clickContinue}/>
                    </>
                )
            }

        </div>
    )
}   

export default LifestyleResult;