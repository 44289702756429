import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/plan/session.json";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./Session.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const Session = props => {
    const { nextStep } = props;
    const [cards, setCards] = useState(data);
    const [activeCard, setActiveCard] = useState();

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_dance_session_appeared');
    }, [])

    const selectCard = (card) => {
        setActiveCard(card)
        amplitude.getInstance().logEvent('button_onboarding_dance_session_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "session");
        answer.set("value", card.title);
        nextStep(answer)
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>{strings.plan.session.title}</h1>
            { cards?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard card={card} selectCard={selectCard} activeCard={activeCard}/>
                </div>)) 
            }
        </div>
    )
}   

export default Session;