import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./GoalBanner.module.scss";

const GoalBanner = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.goalBanner;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_one_million_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_one_million_continue_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            { pageInfo.withUpImage &&
                (<img className={styles.absoluteBackground} src="/images/goals/banner-background-up-ny.png" alt="" />)
            }
            <img className={styles.logoImage} src="/images/Logo.svg" alt="" />
            <h1 className={styles.title}>{strings.goal.bannerTitle}</h1>
            <p className={styles.subtitle}>{strings.goal.bannerSubtitle}</p>
            <img className={`${styles.imageBanner}`} src={pageInfo.imageBanner} style={pageInfo.cardsImageStyle} alt="" />
            <ContinueButton text={strings.continue} nextStep={clickContinue}/>
        </div>
    )
}   

export default GoalBanner;