import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from "react-router";
import { Pagination, Navigation } from "swiper";
import { useDispatch } from "react-redux";
import { useFeatureValue } from "@growthbook/growthbook-react";
import amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import sha256 from 'crypto-js/sha256';
import moment from 'moment/moment';

import GetButton from "../../components/UI/getProgramButton/GetButton";
import ResultsCard from "../../components/UI/paywallResultsCard/ResultsCard";
import StartTrial from "../../components/UI/startTrial/StartTrial";
import StartDiscount from "../../components/UI/startDiscount/StartDiscount";
import XPercentDiscount from "../../components/UI/xPercentDiscount/XPercentDiscount";
import MealPlanBonus from "../../components/UI/mealPlanBonus/MealPlanBonus";
import PaymentRateCards from "../../components/UI/paymentRateCards/PaymentRateCards";
import ImageSaleAd from "../../components/UI/imageSaleAd/ImageSaleAd";
import ErrorBoundary from "../../components/error/errorCatcher";

import paywallData from "../../core/static/paywall.json";

import styles from "./Paywall.module.scss";
import "./Paywall.scss";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

import strings from "../../assets/localization/strings.jsx";
import NutritionSwitcher from "../../components/UI/nutritionSwitcher/NutritionSwitcher";
import PaywallTimer from "../../components/UI/paywallTimer/PaywallTimer";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = '6325c393bf71ea040fa29781d0fb76263e59faf5';

const Paywall = (props) => {
    const { data } = props;

    const productsData = data.products
    const goal = localStorage.getItem('goal') || strings.intro.mainGoal.introGoalFit;
    const discountStatus = localStorage.getItem('discountStatus');
    const pageInfo = discountStatus && discountStatus === 'discount'
                     ? data.settings.paywall_discount
                     : discountStatus && discountStatus === 'trial'
                       ?  data.settings.paywall_trial
                        : data.settings.paywall;
    const targetWeight = localStorage.getItem('targetWeight');
    const country = data.country
    const [nutritionButton, setNutritionButton] = useState(true);
    const [content, setContent] = useState(paywallData);
    const [textButton, setTextButton] = useState(strings.continue);
    const [rateCards, setRateCards] = useState([]);
    const [timer, setTimer] = useState([5,0,0]);
    const [stopTimer, setStopTimer] = useState(false);
    const [activeRate, setActiveRate] = useState(productsData[makeProductsType()][1]);
    const value = useFeatureValue("paywall_testing", "fallback");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const campaignCase = pageInfo.campaignCase || (pageInfo.isACase && country === 'eu')
    const feedbacks = pageInfo.results[country]

    function makeProductsType() {
        if(data.partner)
            return data.partner
        if(country === "ny1")
            return `${country}_${value}`

        let typeValues = [country]
        if(['discount', 'trial'].indexOf(discountStatus) > -1)
            typeValues.push(discountStatus)
        if (data.nutrition !== null && data.nutrition === "onh" && nutritionButton)
            typeValues.push('n')
        return typeValues.join('_')
    }

    const nextPage1 = () => {
    nextPage(1);
    }

    const nextPage2 = () => {
        nextPage(2);
    }

    const nextPage3 = () => {
            nextPage(3);
    }

   const nextPage4 = () => {
                nextPage(4);
   }

    const nextPage = (btnOrder) => {
        amplitude.getInstance().logEvent('button_onboarding_paywall_subscribe_tapped', { paywall: value, order: btnOrder, product: `$${activeRate.total}_${activeRate.title}`});

        localStorage.setItem('timer', JSON.stringify({ minutes: timer[0], seconds: timer[1], milliseconds: timer[2]}));
        if(data.nutrition !== null && data.nutrition === 'onh')
            localStorage.setItem('with_nutrition', nutritionButton);
        setStopTimer(true);
        navigate('/payment')
    }

    const setActiveRateCard = (card) => {
        if(card.checked) {
            setRateCards(rateCards.map(item => {
                if(card.id === item.id) {
                    return {...item, checked: card.checked}
                } else {
                    return {...item, checked: false}
                }
            }))
            setActiveRate(card)
            dispatch({type: 'SELECT_PERIOD', payload: card})
        }
    }

    useEffect(() => {
        if(discountStatus) {
            if(discountStatus === 'trial') {
                setTextButton('Start my 7 day trial')
            }
            if(discountStatus === 'discount') {
                setTextButton(strings.continue)
            }
        }

          try{
              const eventId = `paywall_open.${getCookie('id')}`;
              ReactPixel.track('PaywallOpen', {eventID: eventId});
              TiktokPixel.track('PlaceAnOrder', {event_id: eventId});

              const payload = {
                              data: [
                                {
                                  event_name: "PaywallOpen",
                                  event_time: moment().unix(),
                                  action_source: "website",
                                  event_id: eventId,
                                  user_data: {
                                      client_user_agent: navigator.userAgent,
                                      external_id: sha256(getCookie('id')).toString()
                                  }
                                }
                              ]
                            };
                            const fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]).join();
                            const fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]).join();

                            if(fbc) {
                              payload.data[0].user_data.fbc = fbc;
                            }

                            if(fbp) {
                              payload.data[0].user_data.fbp = fbp;
                            }

                            fetch(`https://graph.facebook.com/v10.0/${(data.domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${data.domain.includes('net')? netToken: token}`, {
                              method: "POST",
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              body: JSON.stringify(payload)
                            })

           const ttPayload = {

                                     event_source: "web",
                                     event_source_id: "CP2RST3C77UF83EV1CUG",
                                     data: [
                                       {
                                         event: "PlaceAnOrder",
                                         event_time: moment().unix(),
                                         event_id: eventId,
                                         user: {
                                             user_agent: navigator.userAgent,
                                             external_id: sha256(getCookie('id')).toString()
                                         }
                                       }
                                     ]
                                   };

                                   const ttc = document.cookie.split(';').filter(c => c.includes('tiktok_click_id=')).map(c => c.split('tiktok_click_id=')[1]).join();
                                   const ttp = document.cookie.split(';').filter(c => c.includes('_ttp=')).map(c => c.split('_ttp=')[1]).join();

                                   if(ttc) {
                                     ttPayload.data[0].user.ttclid = ttc;
                                   }

                                   if(ttp) {
                                     ttPayload.data[0].user.ttp = ttp;
                                   }
//                                    try{
//                                    fetch(`https://business-api.tiktok.com/open_api/v1.3/event/track`, {
//                                                                         method: "POST",
//                                                                         headers: {
//                                                                           'Access-Token': tiktokApiToken,
//                                                                           'Content-Type': 'application/json'
//                                                                         },
//                                                                         body: JSON.stringify(ttPayload)
//                                      })
//                                    } catch(e){
//                                      console.log("tiktok track event error " + e);
//                                    }


          } catch(e){
           console.log("event error: " + e)
          }

        let paywallPrices = makeProductsType()
        let actualRateCarts = productsData[paywallPrices].map(item => { return {...item, checked: false } })
        let activeRateIndex = activeRate.id
        let currActiveRate = { ...actualRateCarts[activeRateIndex], checked: true }
        setActiveRate(currActiveRate)
        actualRateCarts[activeRateIndex] = currActiveRate
        setRateCards(actualRateCarts)
        localStorage.setItem('period', JSON.stringify(actualRateCarts[activeRateIndex]))
        amplitude.getInstance()
            .logEvent('screen_onboarding_paywall_appeared', { paywall: paywallPrices})
        // if(value === 'a' || value === 'b' || value === 'c') {
        //     setRateCards(productsData[country][value])
        //     localStorage.setItem('period', JSON.stringify(productsData[country][value][1]))
        //     setActiveRate(productsData[country][value][1])
        //     setTest(value)
        //     amplitude.getInstance().logEvent('screen_onboarding_paywall_appeared', { paywall: `${value}_${country}`});
        // }
        // if(discountStatus && discountStatus === 'trial') {
        //     setTextButton('Start my 7 day trial')
        //     setRateCards(productsData[country]['trial'])
        //     localStorage.setItem('period', JSON.stringify(productsData[country]['trial'][1]))
        //     setActiveRate(productsData[country]['trial'][1])
        //     setTest('trial')
        //     amplitude.getInstance().logEvent('screen_onboarding_paywall_appeared', {paywall: `trial_${country}`});
        // }
    // }, [value, discountStatus])
    }, [discountStatus, nutritionButton])

    useEffect(() => {
        localStorage.setItem('period', JSON.stringify(activeRate))
    }, [activeRate])

     function getCookie(name) {
          const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
          ));
          return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    return (
        <div className={`${styles.mainContainer}`}>
            <div className={`${styles.logoContainer} flex-between`}>
                <img src="/images/Logo.svg" alt="" />
                {
                    !stopTimer ? (
                        <ErrorBoundary>
                        <PaywallTimer timer={timer} setTimer={setTimer} activeRate={activeRate} stopTimer={stopTimer} setStopTimer={setStopTimer} />
                        </ErrorBoundary>
                    ) : (
                        <GetButton small={true} text={textButton} nextPage={nextPage1}/>
                    )
                }
            </div>

            { discountStatus && !data.partner && discountStatus === 'trial' && (<StartTrial />) }
            { data.campaign !== 'sh032470' && discountStatus && !data.partner && discountStatus === 'discount' && (<StartDiscount />) }
            { (data.campaign === 'shret022470' || data.campaign === 'chl_ret70' || data.campaign === 'ret70') && (<XPercentDiscount discount={70}/>) }
            { discountStatus && !data.partner && discountStatus === 'discount' && data.campaign === 'sh032470' && (<XPercentDiscount previousDiscount = {(discountStatus && !data.partner && discountStatus === 'discount')? 70 : null } discount={(discountStatus && !data.partner && discountStatus === 'discount')? 75 : 70 }/>) }
            {
                pageInfo.campaignImageSale ?
                    (<ImageSaleAd campaign={data.campaign} pageInfo={pageInfo} style={data.settings.paywall.style} />) :
                    (<img className={styles.galleryImage} src="/images/paywall/a/paywall-a.gif" alt="" />)
            }
            <h1 className={styles.title1}>{strings.paywall.title.start}<span>{strings.paywall.title.personal}</span></h1>
            <h1 className={styles.title1}>{strings.paywall.title.end}</h1>
            <div className={`${styles.selectedDataContainer} flex-between`}>
                <div className={`${styles.selectedItem} flex-row`}>
                    <div className={styles.circleContainer}>
                        <img src="/images/result/goal-result.jpg" alt="" />
                    </div>
                    <div className={`${styles.itemText} flex-column`}>
                        <p className={styles.label}>{strings.paywall.goal}</p>
                        <p className={styles.content}>{goal}</p>
                    </div>
                </div>
                <div className={styles.verticalLine}></div>
                <div className={`${styles.selectedItem} flex-row`}>
                    <div className={styles.circleContainer}>
                        <img src="/images/result/scales.jpg" alt="" />
                    </div>
                    <div className={`${styles.itemText} flex-column`}>
                        <p className={styles.label}>{strings.paywall.targetWeight}</p>
                        <p className={styles.content}>{targetWeight}</p>
                    </div>
                </div>
            </div>
            { campaignCase && (<MealPlanBonus campaign={data.campaign}
                                              img={(discountStatus && discountStatus === 'discount')
                                                   ? strings.imgs[data.settings.paywall_discount.mealBonusImgLocalId]
                                                   : (discountStatus && discountStatus === 'trial')
                                                      ? strings.imgs[data.settings.paywall_trial.mealBonusImgLocalId]
                                                      : strings.imgs[data.settings.paywall.mealBonusImgLocalId]}
                                              style={data.settings.paywall.style}
                                              discount={!!(discountStatus && discountStatus === 'discount') }
                                              withNutrition={(data.nutrition !== null && data.nutrition === 'onh')}
                                              nextText = {textButton}
            />) }
            <h1 className={styles.title1}>{strings.paywall.cardHeader.title[pageInfo.cardsTitleID]}</h1>
            { data.nutrition !== null && data.nutrition === 'onh' && (<NutritionSwitcher onClickAction={setNutritionButton} />) }
            <PaymentRateCards
                textButton={textButton}
                nextPage={nextPage2}
                rateCards={rateCards}
                campaign={data.campaign}
                pageInfo={pageInfo}
                activeRate={activeRate}
                setActiveRateCard={setActiveRateCard}
                campaignCase={campaignCase}
                discount={(discountStatus && discountStatus === 'discount')
                          ? (data.campaign === "sh032470" ? 75 : 60)
                          : (data.campaign === "shret022470" || data.campaign === "sh032470" || data.campaign === "chl_ret70" || data.campaign === "ret70" )
                               ? 70
                               : 50}
                style={data.settings.paywall.style}
            />
            <p className={styles.rateNote}>{strings.paywall.cancelAnytime}</p>
            {
                !campaignCase ? (
                    <>
                        <h2 className={styles.titleProgramDescription}>{strings.paywall.get}</h2>
                        <div className={styles.descriptionContainer}>
                            { content.programDescription.map(item => (
                                <div key={item.id} className={`${styles.itemContainer} flex-row`}>
                                    <img src={item.icon} alt="" />
                                    <p>{strings.paywall.features[item.id]}</p>
                                </div>
                            ))}
                        </div>
                        {/*<GetButton text={textButton} nextPage={nextPage}/>*/}
                    </>
                ) : null
            }
            <h2 className={`${styles.titleProgramDescription} ${styles.marginTitle}`}>{strings.paywall.featured}</h2>
            <img className={styles.featuresImage} src="/images/paywall/features-image.png" alt="" />
            <div className={`${styles.resultsContainer} resultsContainer flex-column`}>
                <h2>{strings.paywall.peopleLikeYou}<span>{strings.paywall.plan}</span></h2>
                <Swiper
                    className="mySwiper"
                    spaceBetween={30}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                >
                    {
                        feedbacks.map(result => (
                            <SwiperSlide key={result.id}>
                                <ResultsCard card={result}/>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <p className={styles.disclaimer}><b>{strings.paywall.disclaimer}</b>{strings.paywall.key}</p>
            <PaymentRateCards
                textButton={textButton}
                nextPage={nextPage3}
                rateCards={rateCards}
                campaign={data.campaign}
                pageInfo={pageInfo}
                activeRate={activeRate}
                setActiveRateCard={setActiveRateCard}
                campaignCase={campaignCase}
                discount={(discountStatus && discountStatus === 'discount')
                                          ? (data.campaign === "sh032470" ? 75 : 60)
                                          : (data.campaign === "shret022470" || data.campaign === "sh032470" || data.campaign === "chl_ret70" || data.campaign === "ret70")
                                               ? 70
                                               : 50}
                style={data.settings.paywall.style}
            />
            <p className={styles.rateNote}>{strings.paywall.cancelAnytime}</p>
            <h2 className={styles.questionsTitle}>{strings.paywall.peopleAsk}</h2>
            {
                content.questions.map(question => (
                    <div className={styles.questionContainer} key={question.id}>
                        <div className={`${styles.titleContainer} flex-row`}>
                            <img src="/images/paywall/question-icon.png" alt="" />
                            <p>{strings.paywall.questions[question.id].title}</p>
                        </div>
                        <p className={styles.subtitle}>{strings.paywall.questions[question.id].subtitle}</p>
                    </div>
                ))
            }
            <h2 className={`${styles.questionsTitle} ${styles.marginFeedbackTitle}`}>{strings.paywall.love}</h2>
            {
                content.comments.map(comment => (
                    <div key={comment.id} className={styles.commentContainer}>
                        <div className={styles.titleContainer}>
                            <h2>{strings.paywall.comments[comment.id].title}</h2>
                            <img src="/images/paywall/stars-image.jpg" alt="" />
                        </div>
                        <p>{strings.paywall.comments[comment.id].subtitle}</p>
                        <div className={`${styles.authorContainer} flex-between`}>
                            <span>{strings.paywall.comments[comment.id].author}</span>
                            <span>{strings.paywall.comments[comment.id].date}</span>
                        </div>
                    </div>
                ))
            }
            <div className={styles.guaranteeContainer}>
                <div className={styles.wrapper}>
                    <h2>{strings.paywall.guarantee}</h2>
                    <p>{strings.paywall.results}<br/><br/>{strings.paywall.return}</p>
                    <img src="/images/paywall/guarantee-icon.png" alt="" />
                </div>
            </div>
            <p className={styles.guaranteeNote}><b>{strings.paywall.note}</b>{strings.paywall.subscrRenew}</p>
            <GetButton text={textButton} nextPage={nextPage4}/>
        </div>
    )
}   

export default Paywall;