import styles from "./GetButton.module.scss";

const GetButton = props => {
    const { nextPage, small, text = 'Get my program' } = props;

    return (
        <button className={`${styles.buttonContainer} ${small ? styles.smallButton : ''}`} onClick={nextPage}> 
            <span className={styles.buttonText}>{text}</span>
            <span className="pulse-button__rings"></span>
            <span className="pulse-button__rings"></span>
            <span className="pulse-button__rings"></span>
        </button>
    )
}   

export default GetButton;