import {useEffect} from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./AboutResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const AboutResult = props => {
    const { nextStep, data } = props;
    const ageInfo = localStorage.getItem('ageInfo');
    const targetWeight = localStorage.getItem('targetWeight');
    const pageInfo = data.settings.aboutResult

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_about_you_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_about_you_tapped');
        nextStep();
    }

    return (
        <>
{/*             { (data.campaign === 'bf' || data.campaign === 'sh' || data.campaign === 'shret022470' || data.campaign === 'sh032450' || data.campaign === 'sh032470') && */}
{/*                 (<img className={styles.absoluteImage} src="/images/result/about-result-image-bf.png" alt="" />) */}
{/*             } */}
            { data.campaign === 'ny'
                ? (<>
                    <img className={`${styles.absoluteBackground} ${styles.absoluteBackgroundLeft}`} src="/images/result/about-result-background-left-ny.png" alt="" />
                    <img className={`${styles.absoluteBackground} ${styles.absoluteBackgroundRight}`} src="/images/result/about-result-background-right-ny.png" alt="" />
                    <img className={styles.absoluteImageNy} src={pageInfo.decoration_image} alt="" />
                </>)
                : (pageInfo.decoration_image && <img className={styles.absoluteImage} src={strings.imgs[pageInfo.imageLocalID]} alt="" />)

            }
            <div className={`${styles.mainContainer} flex-column`}>
                <ResultHeader url={'/images/result/about-result.jpg'}/>
                { pageInfo.withWeightInfo ? (
                    <div className={`${styles.bVariantResult} flex-column`}>
                        <img className={styles.image} src={pageInfo.image} alt="" />
                        <h1>{strings.about.result.title}{ageInfo}</h1>
                        <p className={`${styles.text} resultSubtitle`}>
                            {strings.about.result.subtitleStart}<b>{targetWeight}</b>,{strings.about.result.subtitleEnd}
                        </p>
                    </div>
                    ) : (
                        <>
                            <h1>We Got You!</h1>
                            <span className={`${styles.subtitle} componentSubtitle`}>
                                <span className={styles.percentage}>88,6%</span> of users report positive changes
                                <b>after 1-week</b> of use.
                            </span>
                            <img className={styles.image} src="/images/result/about-image.jpg" alt="" />
                            <p className={`${styles.text} resultSubtitle`}>Let's learn more about your lifestyle</p>
                        </>
                    )
                }
                <div className="buttonContainer">
                    <ContinueButton nextStep={clickContinue}/>
                </div>
            </div>
        </>
    )
}   

export default AboutResult;