import styles from "./StartTrial.module.scss";

const StartTrial = () => {
    return (
        <div className={styles.startTrialBox}>
            <div className={styles.startTrialContainer}>
                <div className={styles.startTrial}>
                    <h2>
                        Start your personal plan with
                        <br/>
                        <span className={styles.redText}>7-day trial</span>
                    </h2>
                    <div className={styles.giftImage}>
                        <img src="/images/gift_icon.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
      )
  }

export default StartTrial;