import styles from "./RefundBox.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const RefundBox = () => {
    return (
        <>
            <div className={styles.refundBox}>
                <div className={styles.refundBoxContainer}>
                    <div className={styles.refundBoxContent}>
                        <div className={styles.cupImage}>
                            <img src="/images/paywall/icons/goal-result.svg" alt="" />
                        </div>
                        <div className={styles.refundBoxTitle}>
                            <p>
                                {strings.paywall.refund.value}
                                <br />
                                <span className={styles.boldText}>{strings.paywall.refund.rate}</span>
                                <br />
                                {strings.paywall.refund.year}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p className={styles.refundBoxText}>{strings.paywall.refund.days}<span className={styles.redText}>{strings.paywall.refund.mbg}</span></p>
        </>
      )
  }

export default RefundBox;