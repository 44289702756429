import { useEffect } from "react";
import amplitude from 'amplitude-js';

import styles from "./DanceVsExercises.module.scss";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";

const DanceVsExercises = props => {
    const { nextStep } = props;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_your_plan_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_your_plan_continue_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader url={'/images/result/plan-result.jpg'}/>
            <h1>{strings.plan.danceExercise.title}</h1>
            <span className={`${styles.marginTitle}`}>{strings.plan.danceExercise.subtitle}</span>
            <div className={`${styles.chartArea}`}>
                <img src="/images/result/danceVsExercises.png" alt="" />
            </div>
            <div className={`${styles.infoBox} flex-row`}>
                <img className={styles.backhandImage} src="/images/icons/up_pointing_backhand.png" alt="" />
                <p className={styles.subtitle}>
                    {strings.plan.danceExercise.info}
                </p>
            </div>
            <ContinueButton text={'Continue'} nextStep={clickContinue}/>
        </div>
    )
}   

export default DanceVsExercises;