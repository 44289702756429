import styles from "./ContinueButton.module.scss";
import strings from "../../../assets/localization/strings.jsx";
const ContinueButton = props => {
    const { text = strings.continue, nextStep, disableButton, customStyles } = props;

    return (
        <button className={`${styles.buttonContainer} ${disableButton ? styles.disabledButton : ''}`}
                style={customStyles}
                onClick={nextStep}
                disabled={!!disableButton}
        >
            {text}
            <img src="/images/icons/white-arrow.svg" alt="" />
        </button>
    )
}   

export default ContinueButton;