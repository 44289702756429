import { useEffect, useState } from 'react';
import amplitude from 'amplitude-js';
import { useNavigate } from "react-router-dom";
import styles from "./Email.module.scss";

import ContinueButton from "../../components/UI/continueButton/ContinueButton";

const Email = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_email_appeared');
        setEmail(localStorage.getItem('email'));
    }, [])

    const clickButton = () => {
        if(!email) {
            setError('This field shouldn’t be empty');
        } else if(!error) {
            localStorage.setItem('email', email)
            amplitude.getInstance().logEvent('button_onboarding_email_continue_tapped');
            navigate("/paywall");
        }
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    
    const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
            setError('Email seems to be invalid');
        } else {
            setError(null);
        }

        setEmail(event.target.value);
    };

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>Enter your email to get a <span>Personalized Dancing Weight Loss Plan</span></h1>
            <input 
                className={`${styles.emailInput} ${error ? styles.error : ''}`} 
                type="email"
                pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                required 
                placeholder='Enter your email to get your plan'
                value={email}
                onChange={handleChange}
            />
            {error && <p className={styles.errorText}>{error}</p>}
            <div className={styles.description}>
                <img src="/images/icons/lock-icon.png" alt="" />
                <p>We respect your privacy and are committed to protecting your personal data. We’ll email you a copy of your results for convenient access.</p>
            </div>
            <ContinueButton nextStep={clickButton}/>
        </div>
    )
}   

export default Email;