
import styles from "./SquareCard.module.scss";

const SquareCard = props => {
    const { card, cardHeight, selectCard, activeCard } = props;

    return (
        <>
            <div className={`${styles.cardContainer} ${activeCard?.id === card.id  ? styles.activeCard : ''} flex-column cardContainer`}
                 style={{height: cardHeight ? `${cardHeight}px` : ''}}
                 onClick={() => selectCard(card)}
            >
                <img className={styles.cardIcon} src={card.icon} alt="" />
                <p className={`${styles.cardTitle} cardTitle`} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{card.title}</p>
            </div>
            {
                card.message && activeCard?.id === card.id ? (
                    <p className={styles.cardMessage}>{card.message}</p>
                ) : null
            }
        </>
    )
}   

export default SquareCard;