import styles from "./RateCard.module.scss";

import CustomCheckbox from "../checkbox/Checkbox";
import strings from "../../../assets/localization/strings.jsx";

const RateCardHeader = props => {
    const { isCampaign, isPopular, isActive, pageInfo, discount } = props;
    const imageUrl = isActive ? pageInfo.cardImage : pageInfo.cardInactiveImage

    return (
        <div className={`${styles.header} flex-row`}>
            {
                isCampaign ? (
                    isPopular ? (
                        <>
                            <div className={`${styles.saleHeader} flex-row`}>
                                { pageInfo.saleID !== "trial"  && (<span style={{"fontSize": strings.paywall.cardHeader.save.fontSize}}>{strings.paywall.cardHeader.save.text}{discount}%</span>) }
                                { pageInfo.saleID === "trial"  && (<span style={{"fontSize": strings.paywall.cardHeader.trial.fontSize}}>{strings.paywall.cardHeader.trial.text}</span>) }

                                <img className={styles.imageSaleStar} src={imageUrl} alt="" />
                                <span style={{"fontSize": strings.paywall.cardHeader.mostPopular.fontSize}}>{strings.paywall.cardHeader.mostPopular.text}</span>
                                <img className={styles.imageSaleStar} src={imageUrl} alt="" />

                                 { pageInfo.saleID !== "trial" && (<span style={{"fontSize": strings.paywall.cardHeader.save.fontSize}}>{strings.paywall.cardHeader.save.text}{discount}%</span>) }
                                 { pageInfo.saleID === "trial" && (<span style={{"fontSize": strings.paywall.cardHeader.trial.fontSize}}>{strings.paywall.cardHeader.trial.text}</span>) }

                            </div>
                        </>
                    ) : (
                           <>
                             { pageInfo.saleID !== "trial" && (<span style={{"fontSize": strings.paywall.cardHeader.save.fontSize}}>{strings.paywall.cardHeader.save.text}{discount}%</span>) }
                             { pageInfo.saleID === "trial" && (<span style={{"fontSize": strings.paywall.cardHeader.trial.fontSize}}>{strings.paywall.cardHeader.trial.text}</span>) }
                           </>
                        )
                ) : (
                    <>
                        <img src="/images/icons/like.png" alt="" />
                        <p>Most popular</p>
                    </>
                )
            }
        </div>
    )
}

const RateCard = props => {
    const { card, setActiveCard, activeCard, campaign, pageInfo, discount, style } = props;
    const selectCard = card => {
        setActiveCard(card)
    }
    const blackFridayCase = style === "bf"
    //campaign === 'bf' || campaign === 'sh' || campaign === 'shret022470' || campaign === 'sh032450' || campaign === 'sh032470'
    const newYearCase = style === "ny"
    //campaign === 'ny'
    const campaignStyle = blackFridayCase ? styles.blackFriday :
        newYearCase ? styles.newYear : ""
    const activeCampaignStyle = blackFridayCase ? styles.activeBlackFridayPopular :
        newYearCase ? styles.activeNewYearPopular : ""
    const isCampaign = blackFridayCase || newYearCase
    let isActive = activeCard?.id === card?.id;
    let isActiveCampaignPopular = isActive && isCampaign && card?.id === 1;
    const checkboxColor = blackFridayCase && isActiveCampaignPopular ? 'yellow' :
        newYearCase && isActiveCampaignPopular ? 'green' : 'pink'
    const withHeader = card?.id === 1 || isCampaign
    const cardContainerClass = `${isActive ? styles.activeCard : ''} ${withHeader ? styles.popular : ''} ${campaignStyle} ${isActiveCampaignPopular ? activeCampaignStyle : ''}`

    return (
        <div
            className={`${styles.cardContainer} ${cardContainerClass} flex-column`}
            onClick={() => setActiveCard({...card, checked: !card.checked})}
        >
            { withHeader && (
                <RateCardHeader
                    isActive={isActive}
                    isPopular={card?.id === 1}
                    pageInfo={pageInfo}
                    isCampaign={isCampaign}
                    discount={discount}
                />
            )}
            <div className={`${styles.wrapper} flex-between`}>
                <div className={`flex-row`}>
                    <CustomCheckbox data={card} color={checkboxColor} checkCard={selectCard}/>
                    <div className={`${styles.priceContainer} flex-column`}>
                        <h3 className={styles.title}>{strings.paywall.products[card.title]}</h3>
                        <div className={`flex-row`}>
                            <p className={styles.oldPrice}>${card.oldTotal} <span></span></p>
                            <p className={styles.newPrice}>${card.total}</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.rightContent} flex-row`}>
                    <p className={`${styles.oldPricePerDay} ${styles.oldPrice}`}>${card.oldPerDay.toFixed(2)} <span></span></p>
                    <div className={`${styles.perDayContainer} flex-row`}>
                        <p className={styles.dollar}>$</p>
                        <h1 className={styles.beforeDot}>{Math.trunc(card.newPerDay)}</h1>
                        <div className={`${styles.subtitleContainer} flex-column`}>
                            <span className={styles.afterDot}>{Math.floor ((card.newPerDay - Math.floor (card.newPerDay)) * 100)}</span>
                            <p className={styles.subtitle}>{strings.paywall.cardHeader.perDay}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default RateCard;