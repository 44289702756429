import { useEffect } from 'react';
import { useDispatch } from "react-redux";

import styles from "./PaywallTimer.module.scss";
import strings from "../../../assets/localization/strings";

const PaywallTimer = props => {
    const { timer, setTimer, stopTimer, setStopTimer, activeRate } = props;
    const dispatch = useDispatch();

    useEffect(()=>{
        if(localStorage.getItem('timer')) {
            const storageTimer = JSON.parse(localStorage.getItem('timer'));
            setTimer([storageTimer.minutes, storageTimer.seconds, storageTimer.milliseconds]);
            localStorage.removeItem('timer');
        }
        const timerID = setInterval(() => tick(), 17);
        dispatch({type: 'SELECT_PERIOD', payload: activeRate})
        return () => clearInterval(timerID);
    })

    const tick = () => {
        let m = timer[0]
        let s = timer[1]
        let ms = timer[2]

        if(stopTimer) return;
        if (m === 0 && s === 0 && ms === 0) {
            setStopTimer(true);
        } else if (s === 0 && ms === 0) {
            setTimer([m - 1, 59, 59]);
        } else if (ms === 0) {
            setTimer([m, s - 1, 59]);
        } else {
            setTimer([m, s, ms - 1]);
        }
    }

    return (
        <div className={`${styles.timerContainer} flex-column`}>
            <p>{strings.paywall.reserved}</p>
            <span>{`${timer[0].toString().padStart(2, '0')}:${timer[1].toString().padStart(2, '0')}:${timer[2].toString().padStart(2, '0')}`}</span>
        </div>
    )
}

export default PaywallTimer;
