import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./NutritionResult.module.scss";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import commentsData from "../../../core/static/nutrition/comments.json";
import './swiperStyles.scss';
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";


const NutritionResult = props => {
    const { nextStep } = props;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_nutrition_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_nutrition_continue_tapped');
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader url={'/images/nutrition/nutrition-result.jpg'}/>
            <h1 className={`${styles.title} flex-row`}>
                <img className={styles.bellImage} src="/images/nutrition/icons/bell.png" alt="" />
                {strings.nutrition.nutritionResult.title}
                <img className={styles.bellImage} src="/images/nutrition/icons/bell.png" alt="" />
            </h1>
            <div className={`${styles.commentArea} flex-row`}>
                <img className={styles.image} src="/images/nutrition/comment-image.png" alt="" />
                <p className={styles.subtitle}>
                    {strings.nutrition.nutritionResult.comment}
                </p>
            </div>
            <div className={`${styles.swiperArea} swiper-area`}>
                <Swiper
                    className="nutritionComments"
                    slidesPerView={"auto"}
                    spaceBetween={30}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={true}
                    navigation={true}
                    modules={[Autoplay, Navigation]}
                >
                    { commentsData?.map(comment => (
                        <SwiperSlide key={comment.id}>
                            <div className={`${styles.commentContainer} flex-column`}>
                                <img className={styles.commentImage} src={comment.image} alt="" />
                                <p className={styles.text}>
                                    {strings.nutrition.nutritionResult.feedbacks[comment.localID].text}
                                </p>
                                <p className={styles.text}>
                                    {strings.nutrition.nutritionResult.feedbacks[comment.localID].name}
                                </p>
                            </div>
                        </SwiperSlide>
                    ))
                    }
                </Swiper>
            </div>
            <div className={styles.note}>
                {strings.nutrition.nutritionResult.note}
            </div>
            <ContinueButton text={strings.continue} nextStep={clickContinue}/>
        </div>
    )
}

export default NutritionResult;
