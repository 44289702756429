import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import Picker from "../../UI/picker/Picker";
import styles from "./Age.module.scss";
import AboutInput from "../../UI/aboutInput/AboutInput";

import strings from "../../../assets/localization/strings.jsx";

const Age = props => {
    const { nextStep, data } = props;
    const [age, setAge] = useState(25)
    const [buttonStyles, setButtonStyles] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const ages = Array.from(Array(100).keys()).slice(10);
    const [disableButton, setDisableButton] = useState(true);
    localStorage.removeItem('ageInfo');
    const pageInfo = data.settings.aboutAge
    const manualInput = pageInfo.manualInput

    const changeInputAge = data => {
        setErrorMessage(data.errorMessage);
        setDisableButton(data.disableButton);
        if(!data.disableButton)
            setAge(data.value);
    }

    const changeSwipedAge = data => {
        setAge(data + 10);
        setDisableButton(false);
    }

    const inputOnFocus = () => {
        setTimeout(() => {
            setButtonStyles({ position: 'relative', marginTop: '45px', left: 'unset' })
        }, 100);
    };

    const inputOnBlur = () => {
        setTimeout(() => {
            setButtonStyles({})
        }, 200)
    };

    const clickContinue = () => {
        localStorage.setItem('ageInfo', age);
        let logText = manualInput ? 'button_onboarding_age_continue_tapped' : 'button_onboarding_age_tapped'
        amplitude.getInstance().logEvent(logText, {answer: age});
        const answer = new Map();
        answer.set("key", "age");
        answer.set("value", age);
        nextStep(answer);
    }

    useEffect(() => {
        setDisableButton(true);
        amplitude.getInstance().logEvent('screen_onboarding_age_appeared');
        return () => {
            setDisableButton(true);
        }
    }, [])

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{strings.about.age.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{strings.about.age.subtitle}</span>
            { manualInput ? (
                <>
                    <AboutInput
                        checkResult={changeInputAge}
                        onFocus={inputOnFocus}
                        onBlur={inputOnBlur}
                        inputType={strings.about.age.age}
                        min={18}
                        max={100}
                    />
                    { errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>) }
                    <ContinueButton customStyles={buttonStyles} nextStep={clickContinue} disableButton={disableButton}/>
                </>
                ) : (
                    <>
                        <h1 className={styles.ageTitle}>{age}</h1>
                        <span className={styles.ageSubtitle}>years old</span>
                        <div className={styles.pickerContainer}>
                            <Picker data={ages} currentValue={15} swipeValue={changeSwipedAge}/>
                            <ContinueButton nextStep={clickContinue} disableButton={disableButton}/>
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default Age;