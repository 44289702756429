import styles from "./PaymentRateCards.module.scss";
import RateCard from "../rateCard/RateCard";
import GetButton from "../getProgramButton/GetButton";
import RefundBox from "../refundBox/RefundBox";

const PaymentRateCards = props => {
    const {
        textButton,
        nextPage,
        rateCards,
        activeRate,
        setActiveRateCard,
        campaignCase,
        campaign,
        pageInfo,
        discount,
        style
    } = props;
    return (
       <>
           <div className={`${styles.cardsContainer} flex-column`}>
               {
                   rateCards.map(card =>
                       <RateCard
                           key={card.id}
                           activeCard={activeRate}
                           setActiveCard={setActiveRateCard}
                           campaign={campaign}
                           pageInfo={pageInfo}
                           card={card}
                           discount={discount}
                           style={style}
                       />)
               }
           </div>
           { campaignCase ? (<RefundBox />) : null }
           <GetButton text={textButton} nextPage={nextPage}/>
       </>
      )
  }

export default PaymentRateCards;
