import { useState, useEffect} from "react";
import amplitude from 'amplitude-js';
import Slider from '@mui/material/Slider';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./ActivityLevel.module.scss";
import "./ActivityLevel.scss";

const ActivityLevel = props => {
    const { nextStep } = props;
    const [step, setStep] = useState(1);
    const [colorThumb, setColorThumb] = useState();
    const [sliderText, setSliderText] = useState({ title: 'Sedentary', subtitle: 'You get no formal exercise and are not physically active during the day'});

    const changeStep = value => {
        setStep(value);
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_activity_level_appeared');
    }, [])

    useEffect(() => {
        switch(step){
            case 1:
                setColorThumb('red');
                setSliderText({ title: strings.lifestyle.activity.levels.sedentary.title, subtitle: strings.lifestyle.activity.levels.sedentary.description});
                break;
            case 2:
                setColorThumb('red');
                setSliderText({ title: strings.lifestyle.activity.levels.lightly.title, subtitle: strings.lifestyle.activity.levels.lightly.description});
                break;
            case 3:
                setColorThumb('yellow');
                setSliderText({ title: strings.lifestyle.activity.levels.moderate.title, subtitle: strings.lifestyle.activity.levels.moderate.description});
                break;
            case 4:
                setColorThumb('blue');
                setSliderText({ title: strings.lifestyle.activity.levels.high.title, subtitle: strings.lifestyle.activity.levels.high.description});
                break;
            default:
                setColorThumb('red');
                setSliderText({ title: strings.lifestyle.activity.levels.sedentary.title, subtitle: strings.lifestyle.activity.levels.sedentary.description});
                break;
        }
    }, [step])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_activity_level_tapped', {answer: sliderText.title});
        const answer = new Map();
        answer.set("key", "activity_level");
        answer.set("value", sliderText.title);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} activityContainer flex-column`}>
            <h1 className={styles.cardTitle}>{strings.lifestyle.activity.title}</h1>
            <span className={`${styles.subtitle} componentSubtitle`}>{strings.lifestyle.activity.subtitle}</span>
            <div className={styles.circleContainer}>
                <img className={styles.circleImage} src="/images/icons/fire-3x.png" alt="" />
                <svg>
                    <defs>
                        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#DD3787" />
                        <stop offset="100%" stopColor="#FD6C59" />
                        </linearGradient>       
                    </defs>
                    <circle cx="87.5" cy="87.5" r="87.5" stroke="url(#gradient)" strokeDashoffset={`calc(550px - (550px * ${step * 25}) / 100)`}></circle>
                </svg>
            </div>
            <div className={`${styles.sliderContainer} flex-column`}>
                <h4 className={styles.circleTitle}>{sliderText.title}</h4>
                <span className={`${styles.circleSubtitle} componentSubtitle`}>{sliderText.subtitle}</span>
                <Slider
                    className={colorThumb}
                    value={step}
                    step={1}
                    marks
                    min={1}
                    max={4}
                    onChange={e => changeStep(e.target.value)}
                />
                <div className={`${styles.sliderLabels} flex-between`}>
                    <span>{strings.lifestyle.activity.levels.sedentary.title}</span>
                    <span>{strings.lifestyle.activity.levels.high.title}</span>
                </div>
                <ContinueButton nextStep={clickContinue}/>
            </div>
        </div>
    )
}   

export default ActivityLevel;