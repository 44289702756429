import styles from "./XPercentDiscount.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const XPercentDiscount = (props) => {
    const { discount, previousDiscount } = props;
    return (
        <div className={styles.discountBox}>
            <div className={styles.discountContainer}>
            { previousDiscount !== null && previousDiscount !== undefined && (

                            <div className={styles.oldDiscount}>
                                <div className={styles.text}>
                                  {strings.paywall.discount.previous}<span className={styles.percent}>{previousDiscount}%</span>
                                  <span className={styles.line}></span>
                                </div>
                            </div>
            ) }

                <div className={styles.newDiscount}>
                    <h2>{strings.paywall.discount.titleGet}
                       <br/>
                       <span className={styles.percent}>{discount}%</span>{strings.paywall.discount.discount}</h2>
                    <div className={styles.giftImage}>
                        <img src="/images/gift_icon.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
      )
  }

export default XPercentDiscount;