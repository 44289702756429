import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/lifestyle/diet.json";
import TextCard from "../../UI/textCard/TextCard";
import strings from "../../../assets/localization/strings.jsx";

import styles from "./Diet.module.scss";

const Diet = props => {
    const { nextStep } = props;
    const [cards, setCards] = useState(data);
    const [activeCard, setActiveCard] = useState();

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_diet_appeared');
    }, [])

    const selectCard = (card) => {
        setActiveCard(card);
        amplitude.getInstance().logEvent('button_onboarding_diet_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "diet");
        answer.set("value", card.title);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>{strings.lifestyle.diet.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{strings.lifestyle.diet.subtitle}</span>
            { cards?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard card={card} cardHeight={85} selectCard={selectCard} activeCard={activeCard}/>
                </div> )) 
            }
        </div>
    )
}   

export default Diet;