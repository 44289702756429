import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import StepSlider from '../stepSlider/StepSlider';

import styles from "./SwiperComponent.module.scss";
import "./SwiperComponent.scss";
import 'swiper/css';

const SwiperComponent = props => {
    const { data, defaultSlide, currentSlide, withTitle, sliderTitle, changeSlide } = props;
    const swiperRef = useRef();

    return (
        <>
            <Swiper
                className={styles.swiperContainer}
                spaceBetween={20}
                slidesPerView={"auto"}
                centeredSlides={true}
                initialSlide={currentSlide}
                onSlideChange={slide => changeSlide(slide.activeIndex)}
                onSwiper={(swiper) => swiperRef.current = swiper}
            >
                {data?.map(slide => (
                    <SwiperSlide key={slide.id}>
                        <img src={slide.image} alt="" />
                    </SwiperSlide>
                ))}
            </Swiper>
            { withTitle && <span className={styles.sliderTitle}>{sliderTitle}</span> }
            <StepSlider swiperRef={swiperRef} defaultSlide={defaultSlide} currentSlide={currentSlide} sliderCount={data?.length - 1 || 0}/>
        </>
    )
}   

export default SwiperComponent;