import styles from "./StartDiscount.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const StartDiscount = () => {
    return (
        <div className={styles.startTrialBox}>
            <div className={styles.startTrialContainer}>
                <div className={styles.startTrial}>
                   <div className={styles.discountInfo}>
                    <h1>
                      {strings.paywall.discount.previous}
                      <span className={styles.redTextPrev}>50%</span>
                    </h1>
                    <h2>
                        {strings.paywall.discount.titleStart}
                        <br/>
                        <span className={styles.redText}>60% {strings.paywall.discount.discount}</span>
                     </h2>
                   </div>
                    <div className={styles.giftImage}>
                        <img src="/images/gift_icon.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
      )
  }

export default StartDiscount;