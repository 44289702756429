import { useEffect } from "react";
import amplitude from 'amplitude-js';
import strings from "../../../assets/localization/strings.jsx";
import dinnerData from "../../../core/static/nutrition/dinner.json";
import styles from "./Dinner.module.scss";
import ButtonCard from "../../UI/buttonCard/ButtonCard";


const cardStyles = {
    divStyles: {
        width: 'calc(50% - 8px)',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        padding: '10px 15px'
    },
    pStyles: {
        fontWeight: 400,
        lineHeight: '18px',
        padding: 0
    }
}

const Dinner = props => {
    const { nextStep } = props;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_dinner_appeared');
    }, [])

    const selectCard = card => {
        amplitude.getInstance().logEvent('button_onboarding_dinner_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "dinner");
        answer.set("value", card.title);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>{strings.nutrition.dinner.title}</h1>
            <div className={`${styles.imageContainer}`}>
                <img className={styles.image} src="/images/nutrition/dinner.png" alt="" />
            </div>
            <div className={`${styles.cardsContainer}`}>
                { dinnerData?.map(card => (
                    <ButtonCard key={card.id} card={card} selectCard={selectCard} cardStyles={cardStyles} />
                ))
                }
            </div>
        </div>
    )
}

export default Dinner;