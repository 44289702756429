import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./GoalResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const GoalResult = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.goalResult;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_section_goal_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_goal_tapped');
        nextStep();
    }
    let resultImgPath = data.campaign === "chl" || data.campaign === "chl_ret70"
                        ? "/images/result/goal-result-image_chl.png"
                        : "/images/result/goal-result-image.png"
    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <ResultHeader url={'/images/result/goal-result.jpg'}/>
            {
                pageInfo.asComment ? (
                    <>
                        <img className={styles.image} src={resultImgPath} alt="" />
                        <div className={styles.commentContainer}>
                            <div className={styles.titleContainer}>
                                <h2>{strings.goal.result.reviewTitle}</h2>
                                <img src="/images/stars.svg" alt="" />
                            </div>
                            <p>{strings.goal.result.reviewText}</p>
                            <div className={`${styles.authorContainer} flex-between`}>
                                <span>{strings.goal.result.reviewAuthor}</span>
                            </div>
                        </div>
                        <ContinueButton text={strings.continue} nextStep={clickContinue}/>
                    </>
                ) : (
                    <>
                        <h1>You’re in the right place!</h1>
                        <span className={`${styles.subtitle} componentSubtitle`}>Together we create a dancing journey, that will help you to achieve your goal.</span>
                        <img className={styles.image} src="/images/result/goal-image.jpg" alt="" />
                        <p className={`${styles.text} resultSubtitle`}>But first we need to know a little more about you.</p>
                        <ContinueButton text={'All right'} nextStep={clickContinue}/>
                    </>
                )
            }
        </div>
    )
}   

export default GoalResult;