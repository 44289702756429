import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import useJsonParse from "../../../hooks/useJsonParse";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import SwiperComponent from "../../UI/swiperComponent/SwiperComponent";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./TargetBody.module.scss";

const TargetBody = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.goalTargetBody;
    const bodyImages = useJsonParse(pageInfo.cards);
    const defaultSlide = pageInfo.smallVariant ? 1 : 5;

    const [currentSlide, setCurrentSlide] = useState(defaultSlide);

    const changeSlide = value => {
        setCurrentSlide(value)
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_target_body_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_target_body_tapped', {answer: currentSlide + 1});
        const answer = new Map();
        answer.set("key", "target_body");
        answer.set("value", currentSlide + 1);
        nextStep(answer);
    }

    return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.cardTitle}>{strings.goal.targetBody.titleStart}<span>{strings.goal.targetBody.titleDesired}</span>{strings.goal.targetBody.titleEnd}</h1>
                <SwiperComponent data={bodyImages} defaultSlide={defaultSlide} currentSlide={currentSlide}  sliderTitle={'Target'} changeSlide={changeSlide}/>
                <div className={`${styles.sliderLabels} flex-between`}>
                    <span>{ pageInfo.smallVariant ? strings.goal.targetBody.medium : strings.goal.targetBody.plump }</span>
                    <span>{ pageInfo.smallVariant ? strings.goal.targetBody.curvy : strings.goal.targetBody.skinny }</span>
                </div>
                <ContinueButton nextStep={clickContinue}/>
            </div>
        </>
    )
}   

export default TargetBody;