import { useEffect } from "react";
import { useNavigate } from "react-router";

import ReactPixel from "react-facebook-pixel";
import TiktokPixel from 'tiktok-pixel';
import amplitude from 'amplitude-js';
import moment from "moment/moment";
import sha256 from "crypto-js/sha256";

import ButtonCard from "../../components/UI/buttonCard/ButtonCard";

import useJsonParse from "../../hooks/useJsonParse";
import styles from "./Intro.module.scss";

import strings from "../../assets/localization/strings.jsx";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = '6325c393bf71ea040fa29781d0fb76263e59faf5';

const Intro = props => {
    const { data } = props;
    const pageInfo = data.settings.intro;
    const cards = useJsonParse(pageInfo.mainGoals);
    const navigate = useNavigate();

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_intro_appeared');

        strings.setLanguage(data.language !== null && data.language !== undefined ? data.language :  "en");
        //localStorage.removeItem('discountStatus');
        const eventId = `viewcontent.${getCookie('id')}`;
        ReactPixel.track('ViewContent', {eventID: eventId});
        TiktokPixel.track('ViewContent', {event_id: eventId});
        fetch(`https://graph.facebook.com/v10.0/${(data.domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${data.domain.includes('net')? netToken: token}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                    {
                        event_name: "ViewContent",
                        event_time: moment().unix(),
                        action_source: "website",
                        event_id: eventId,
                        user_data: {
                            client_user_agent: navigator.userAgent,
                            external_id: sha256(getCookie('id')).toString()
                        }
                    }
                ]
            })
        })

        const payload = {

                                                             event_source: "web",
                                                             event_source_id: "CP2RST3C77UF83EV1CUG",
                                                             data: [
                                                               {
                                                                 event: "ViewContent",
                                                                 event_time: moment().unix(),
                                                                 event_id: eventId,
                                                                 user: {
                                                                     user_agent: navigator.userAgent,
                                                                     external_id: sha256(getCookie('id')).toString()
                                                                 }
                                                               }
                                                             ]
                                                           };

                                                           const ttc = document.cookie.split(';').filter(c => c.includes('tiktok_click_id=')).map(c => c.split('tiktok_click_id=')[1]).join();
                                                           const ttp = document.cookie.split(';').filter(c => c.includes('_ttp=')).map(c => c.split('_ttp=')[1]).join();

                                                           if(ttc) {
                                                             payload.data[0].user.ttclid = ttc;
                                                           }

                                                           if(ttp) {
                                                             payload.data[0].user.ttp = ttp;
                                                           }

//                                                            try{
//                                                            fetch(`https://business-api.tiktok.com/open_api/v1.3/event/track`, {
//                                                                                                                         method: "POST",
//                                                                                                                         headers: {
//                                                                                                                           'Access-Token': tiktokApiToken,
//                                                                                                                           'Content-Type': 'application/json'
//                                                                                                                         },
//                                                                                                                         body: JSON.stringify(payload)
//                                                                   })
//                                                            } catch(e){
//                                                            console.log("tiktok track event error" + e)
//                                                            }

    }, [])

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const selectCard = card => {
        localStorage.setItem('goal', strings[card.section][card.page][card.localID].text);
        navigate('steps');
        amplitude.getInstance().logEvent('button_onboarding_intro_tapped', { answer: card.title});
    }

    return (
        <div className={`${styles.pageContainer}`} style={pageInfo.pageBackgroundStyle}>
            <div className={`${styles.mainContainer} flex-column`}>
                <div className={`${styles.backgroundImageBox}`} style={pageInfo.boxBackgroundStyle}>
                    <img className={styles.backgroundImage}
                         style={pageInfo.imageBackgroundStyle}
                         src={strings.intro.backgroundImage !== null
                         ? strings.intro.backgroundImage
                         : pageInfo.backgroundImage} alt=""
                    />
                </div>
                <div className={`${styles.absoluteContainer} flex-column`}>
                    <img className={styles.whiteImage} src="/images/intro/white-logo.png" alt="" />
                    <span className={styles.prefix}>{strings.introPrefix}</span>
                    <h1>{strings.introH1}</h1>
                    <span className={styles.subtitle}>{strings.introSubtitle}</span>
                    <img className={styles.arrowDownImage} src="/images/intro/arrow-down.png" alt="" />
                    <div className={`${styles.cardsContainer} flex-row`}>
                        { cards?.map(card => (
                            <ButtonCard key={card.id} card={card} selectCard={selectCard} />
                        )) }
                    </div>
                    <div className={`${styles.agreements} flex-column`}>
                        <span>{strings.introAgreements}</span>
                        <div className={`${styles.agreementLinks} flex-row`}>
                            <a href={'https://everdance.app/terms'}>Terms of Use</a>
                            <span className={styles.agreementInfo}>|</span>
                            <a href={'https://everdance.app/policy'}>Privacy Policy</a>
                        </div>
                        <span>2023 © {strings.introRights}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default Intro;